import apiClient from '@/services/axios'

export const getUsers = async username => {
  const res = await apiClient.get('/admin/users', { params: { username } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getUsersById = async id => {
  const res = await apiClient.get(`/admin/users/${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postUser = async payload => {
  const res = await apiClient.post('/admin/users', payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const changePassword = async payload => {
  const res = await apiClient.post('/users/changePassword', payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const putUser = async (payload, id) => {
  const res = await apiClient.put(`/admin/users/${id}`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const deleteDataUser = async id => {
  const res = await apiClient.delete(`/admin/users/${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getCustomers = async () => {
  const res = await apiClient.get(`/admin/customers`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getLocations = async (payload, customerId) => {
  const res = await apiClient.get(`/admin/locations`, { params: { payload, customerId } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
