import apiClient from '@/services/axios'

export const getDashboardData = async (year, role, userId) => {
  const res = await apiClient.get('/admin/dashboard', { params: { year, role, userId } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
