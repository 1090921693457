import apiClient from '@/services/axios'

export const getCustomers = async name => {
  const res = await apiClient.get(`/admin/customers`, { params: { name } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getCustomersById = async id => {
  const res = await apiClient.get(`/admin/customers/${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postCustomers = async payload => {
  const res = await apiClient.post('/admin/customers', payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const putCustomers = async (payload, id) => {
  const res = await apiClient.put(`/admin/customers/${id}`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const getLocations = async name => {
  const res = await apiClient.get('/admin/locations', { params: { name } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getLocationsById = async id => {
  const res = await apiClient.get('/admin/locations/' + id)
  if (res) {
    return res.data
  } else {
    return null
  }
}
export const editLocationsById = async (id, payload) => {
  const res = await apiClient.put('/admin/locations/' + id, payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}
export const createLocation = async payload => {
  const res = await apiClient.post('/admin/locations', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}

export const getRooms = async (name, locationId) => {
  const res = await apiClient.get('/admin/rooms', { params: { name, locationId } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getRoomsById = async id => {
  const res = await apiClient.get('/admin/rooms/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const editRoomsById = async (id, payload) => {
  const res = await apiClient.put('/admin/rooms/' + id, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const createRooms = async payload => {
  const res = await apiClient.post('/admin/rooms', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}

export const getLocationByCustomerId = async id => {
  const res = await apiClient.get(`/admin/locations?customerId=${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getRoomByCustomerId = async (customerId, locationId) => {
  const res = await apiClient.get(`/admin/rooms?customerId=${customerId}&locationId=${locationId}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
